import Vue from 'vue';

Vue.mixin({
    methods: {
        stripHTML(value) {
            const div = document.createElement('div')
            div.innerHTML = value
            const text = div.textContent || div.innerText || ''
            return text
        },
    },
});
