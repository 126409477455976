import _ from 'lodash'
import { EventBus } from '@/common/eventBus';

import axios from 'axios'
import { urls, serverUrls } from '@/config'
import httpClient from "@/services/http.service";

export default {
  namespaced: true,
  state: {
    loading: true,
    currentPage: 1,
    perPage: 10,
    perPageMore: 10,
    totalCount: 0,
    currentRightRange: 0,
    list: [],
    columns: [],
    appliedFilters: {
    },
    isHideInactive: false,
    showSetup: false,
    orderInfo: {}
  },
  actions: {
    async load({ commit }, params) {
      /* Emulating network request */

      const { showMore = false, demoPage = false } = params;
      commit('update', { commit, showMore, demoPage })
    }
  },
  mutations: {
    setTotalCount(state, count) {
      state.totalCount = count
    },
    setLoading(state, isLoading) {
      state.loading = isLoading
    },
    setCurrentPage(state, page) {
      state.currentPage = page
    },
    setPerPageAmount(state, perPage) {
      state.perPage = perPage
    },
    update(state, { commit, showMore, demoPage }) {
      commit('setLoading', true)

      const filters = JSON.stringify({
        id: _.get(state, 'appliedFilters.id.value', null),
        status: _.get(state, 'appliedFilters.status_id.value', null),

        tenderName: _.get(state, 'appliedFilters.tender_name.value', null),
        tenderStatus: _.get(state, 'appliedFilters.tender_status.value', null),
        tenderId: _.get(state, 'appliedFilters.tender_id.value', null),

        isHideInactive: state.isHideInactive,
      });

      if (showMore) state.perPage = state.perPage + 10;

      let ordersListMapped = [];
      const currentPage = demoPage ? 1 : state.currentPage;
      const perPage = demoPage ? 10 : state.perPage;
      const apiOrdersUrl = demoPage ? urls.apiOrdersDemoUrl : urls.apiOrdersUrl;

      httpClient.get(`${serverUrls.backendUrl}/${apiOrdersUrl}?perPage=${perPage}&page=${currentPage}&filters=${filters}`)
        .then(response => {
          commit('setLoading', false);
          state.currentRightRange = 10

          if (response && response.data && response.data.data && response.data.data.length) {
            response.data.data.map((item, i) => {
              ordersListMapped[i] = {
                id: item.id,
                status_id: item.status,
                tender_id: item.tender_id,
                tender_name: item.tender_name,
                tender_status: item.tender_status,
                date_end: item.date_end,
                date_start: item.date_start,
              }
            });

            state.list = _.cloneDeep(ordersListMapped)

            state.totalCount = response.data.count;
          } else {
            state.list = [];
            state.totalCount = 0;
          }
        });
    },
    setFilterValue(state, { column, value }) {
      if (!(column in state.appliedFilters)) state.appliedFilters[column] = {};
      if (!state.appliedFilters[column]) state.appliedFilters[column] = {}

      state.appliedFilters[column].value = value;
      state.appliedFilters = _.cloneDeep(state.appliedFilters)

      EventBus.$emit("orderFiltersUpdated");
    },
    resetFilters(state) {
      state.appliedFilters = {};
    },
    setHideInactive(state, hide) {
      state.isHideInactive = hide
    },
    toggleShowSetup(state) {
      state.showSetup = !state.showSetup;
    },
    setColumnsInitial(state, columns) {
      state.columns = columns
    },
    setColumns(state, columns) {
      state.columns = []
      setTimeout(() => {
        state.columns = columns
      },
        100
      );
    },
    setOrderInfo(state, info) {
      state.orderInfo = _.cloneDeep(info)
    }
  },
  getters: {
    loading: state => state.loading,
    list: state => state.list,
    columns: state => state.columns,
    appliedFilters: state => state.appliedFilters,
    isHideInactive: state => state.isHideInactive,
    perPage: state => state.perPage,
    currentPage: state => state.currentPage,
    totalCount: state => state.totalCount,
    showShowMore: state => {
      const rightRange = state.currentRightRange;
      return rightRange < state.totalCount;
    },
    orderInfo: state => state.orderInfo
  },
}
