import Vue from 'vue'
import App from './App.vue'
import store from '@/store'
import router from '@/router'
import VueHead from 'vue-head'
import VueMeta from 'vue-meta'
Vue.config.productionTip = false

import axios from 'axios'
//import axios from './plugins/axios.config'
import VueAxios from 'vue-axios'
import VueScrollTo from 'vue-scrollto'

Vue.use(VueAxios, axios)
Vue.use(VueScrollTo)

import '@/common/mathMixin';
import '@/common/textMixin';
import '@/common/stubMixin';

import { appVersion } from "@/config";
const versionCheckActions = () => {
  const lsAppVersion = localStorage.getItem("APP_VERSION");
  if (appVersion > lsAppVersion) {
    localStorage.setItem("APP_VERSION", appVersion);

    localStorage.setItem("ORDERS_TABLE_SETTINGS", null);
  }
};
versionCheckActions();

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(Buefy, { defaultIconPack: 'fas' })

const moment = require('moment')
require('moment/locale/ru')
Vue.use(require('vue-moment'), { moment })

//Vue.use(require('vue-moment'));

Vue.use(VueHead)
Vue.use(VueMeta, {
  keyName: 'head'
})

Vue.filter('stripHTML', function (value) {
  const div = document.createElement('div')
  div.innerHTML = value
  const text = div.textContent || div.innerText || ''
  return text
});

require('@/assets/main.scss');

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
