/* eslint-disable no-useless-escape */
import _ from 'lodash'
import { urls } from '@/config'
import httpClient from '@/services/http.service'

function processLastIncomeData(string) {
  string = string || ''
  return string.split(' / ').map(item => {
    let itemArr = item.split(' кв.) ')
    return {
      'year': (itemArr[0]) ? itemArr[0].match(/\d+/g)[0] : '',
      'income': itemArr.length == 2 ? itemArr[1].split(' тыс. р.')[0] : '0'
    }
  })
}

export default {
  namespaced: true,
  state: {
    profileData: {
      common: {
        name: { value: '', error: '' },
        position: { value: '', error: '' },
        public_email: { value: '', error: '',regexp: new RegExp (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)  },
        public_phone: { value: '', error: '', lengthParams:{ type:'range', range: [ 11,13 ] }},
        work_phone: { value: '', error: '', lengthParams:{ type:'range', range: [ 11,13 ] } },
        website: { value: '', error: '', regexp: new RegExp(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi)   },
        bio: { value: '', error: '', lengthParams:{  type: 'maxLength', maxLength: 500} },
      },
      account: {
        email: { value: '', error: '', regexp: new RegExp (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) },
        username: { value: '', error: '' },
        current_password: { value: '', error: '', onlyOutcoming: true, fieldType: 'password' },
        new_password: { value: '', error: '', onlyOutcoming: true, fieldType: 'password' },
        password_repeat: { value: '', error: '', onlyOutcoming: true, fieldType: 'password' },
      },
      contractors_data: {
        comment: { value: '', error: '' },
        bank_account: { value: '', error: '', required: true, lengthParams: {type:'exact', exact: 20 }},
        bank_name: { value: '', error: '', required: true, lengthParams:{  type: 'minLength', minLength: 5} },
        bank_location: { value: '', error: '', required: true },
        bik: { value: '', error: '', required: true, lengthParams: {type:'exact', exact: 9}  },
        ceo: { value: '', error: '' },
        charter_member: { value: '', error: '' },
        ceo_email: { value: '', error: '' },
        charter_member_email: { value: '', error: '' },
        company_date_registration: { value: '', error: '', required: true},
        contacts: { value: '', error: ''},
        correspondent_account: { value: '', error: '', required: true, lengthParams:{type:'exact', exact: 20} },
        country: { value: '', error: '', required: true},
        delivery_terms: { value: '', error: ''},
        email: { value: '', error: '', required: true, regexp: new RegExp (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)},
        employees_count: { value: '', error: ''},
        organization_type: { value: '', error: '',},
        actual_address: { value: '', error: '', required: true, lengthParams:{  type: 'minLength', minLength: 10}},
        fax: { value: '', error: ''},
        turnover_last_years: [{ year: '2021', error: '', income: 0 }, { year: '2010', error: '', income: 0 }, { year: '1', error: '', income: 0 } ],
        inn: { value: '', error: '', required: true, lengthParams: { type: 'juridical', juridical:{IP: 12, YurLico: 10 }}},
        leader_mass: { value: '', error: ''},
        founder_mass: { value: '', error: ''},
        kpp: { value: '', error: '', lengthParams: {type:'exact', exact:9}},
        mass_address: { value: '', error: '', required: true, },
        name_full: { value: '', error: '', required: true, lengthParams:{  type: 'minLength', minLength: 5}},
        nds_payer: { value: '', error: ''},
        okpo: { value: '', error: '', lengthParams: { type: 'juridical', juridical:{IP: 8, YurLico: 8 }}},
        ogrn: { value: '', error: '', required: true,  lengthParams: { type: 'juridical', juridical:{IP: 15, YurLico: 13} }},
        organization_phone: { value: '', error: '', required: true,  lengthParams:{ type:'range', range: [ 11,13 ] }},
        has_references: { value: '', error: ''},
        short_name: { value: '', error: ''},
        solving_documentation: { value: '', error: ''},
        producer_or_supplier: { value: '', error: ''},
        company_specialization: { value: '', error: ''},
        type_id: { value: '', error: '', required: true},
        legal_address: { value: '', error: '', required: true, lengthParams:{ type: 'minLength',minLength: 10} },
        labor_resources: { value: '', error: ''},
        // eslint-disable-next-line no-useless-escape
        www: { value: '', error: '', regexp: new RegExp(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi) },
        payer_nds: { value: '', error: '', required: true},
      },
      notifications: [],
      documents: [],
      delegations: {
        my_delegations: [],
        other_delegations: []
      },
      categories: [],
      requests: {
        incoming: [],
        outcoming: []
      }
    },
    userSearchData: [],
    orgStatus: 18,
  },
  actions: {    
    loadUsers({commit}) {
      httpClient
        .get(urls.apiUserSearchUrl+'?role=1')
        .then(({ data }) => {
          if (!data) throw "Couldn't get user search information"
          commit('setUserSearchData', data)          
        })
        .catch((error) => {
          console.info('User Profile Loading Error: ', error)
        })
    },
    loadProfile({commit}) {
      httpClient
        .get(urls.apiUSerProfileUrl)
        .then(({ data }) => {
          if (!data) throw "Couldn't get user profile information"
          commit('setProfileData', data)          
        })
        .catch((error) => {
          console.info('User Profile Loading Error: ', error)
        })
    }
  },
  mutations: {
    setProfileData(state, data) {
      Object.entries(state.profileData).map(
        ([chapterName, chapterFormData]) => {
          if (['notifications', 'documents', 'delegations', 'categories', 'requests'].indexOf(chapterName) != -1)
            state.profileData[chapterName] = data[chapterName]
          else
            Object.entries(chapterFormData).map(([fieldName, fieldData]) => {
              if (fieldData.onlyOutcoming) return

              if (fieldName == 'phone' && data[chapterName][fieldName])
                data[chapterName][fieldName] = data[chapterName][fieldName].replace(/\D/g,'')
              
              state.profileData[chapterName][fieldName].value =
                (data[chapterName][fieldName]) ? data[chapterName][fieldName].toString() : ''
              if (fieldName == 'turnover_last_years')
                state.profileData[chapterName][fieldName] = processLastIncomeData(data[chapterName][fieldName])
            })
            
        }
      )
      
    },
    addRowToLastIncomeData(state) {
      state.profileData.contractors_data.turnover_last_years.push({'year': '', 'income': ''})
    },
    changePasswordView(state, field) {
      console.log(state.profileData.account[field])
      state.profileData.account[field].fieldType = (state.profileData.account[field].fieldType == 'password') ? 'text' : 'password'
    },
    addDelegation(state, data) {
      state.profileData.delegations.my_delegations.push(data)
    },
    updateDelegations(state, data) {
      state.profileData.delegations = _.cloneDeep(data)
    },
    setNotifications(state, data) {
      state.profileData.notifications = _.cloneDeep(data)
    },
    updateRequests(state, data) {
      state.profileData.requests = _.cloneDeep(data)
    },
    updateCategories(state, data) {
      state.profileData.categories = _.cloneDeep(data)
    },
    addNewFile(state, data) {
      const fileId = data.name.split('-')[1]
      const docTemplate = state.profileData.documents.find(item => item.id == fileId)

      if (docTemplate) {
        if (docTemplate.files == null)
          docTemplate.files = _.cloneDeep(data.files)
        else
          data.files.map(item => {
            docTemplate.files.push(item)
          })
      }
    },
    deleteFile(state, data) {
      const fileId = data.name.split('-')[1]
      const docTemplate = state.profileData.documents.find(item => item.id == fileId)

      if (docTemplate) {
        const index = docTemplate.files.findIndex(
          (elem) =>
            elem.name === data.file.name &&
            elem.size === data.file.size &&
            elem.date === data.file.date
        )
        docTemplate.files.splice(index, 1)
        httpClient
          .delete(urls.apiUSerProfileDocumentUrl+'?id='+data.file.id)
          .then(({ response }) => {
            if (!response) throw "Couldn't get user profile information"
          })
          .catch((error) => {
            console.info('User Profile Loading Error: ', error)
          })
      }
    },
    setUserSearchData(state, data) {
      state.userSearchData = _.cloneDeep(data) 
    },
    setStatus(state, status) { 
      state.orgStatus = status === null ? 17 : status
    },
  },
  getters: {
    profileData: state => state.profileData,
    userSearchData: state => state.userSearchData,
    filesListById: (state) => (name) => {
      const fileId = name.split('-')[1]
      const docTemplate = state.profileData.documents.find(item => item.id == fileId)
      return docTemplate.files || []
    },
    orgStatus: state => state.orgStatus,
  },
}
