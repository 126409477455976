//import axios from 'axios'
//import { urls } from '@/config'
import _ from 'lodash'
import httpClient from "@/services/http.service";
import { formatSpacesThousands } from "@/common/math";
import { urls } from '@/config'

const items = {
  companies: {
    id: 1,
    img: require('@/assets/img/hammer-icon.svg'),
    number: '32 589',
    text: 'Количество зарегистрированных компаний',
    field: 'companies'
  },
  tenders: {
    id: 2,
    img: require('@/assets/img/people-icon.svg'),
    number: '51 876',
    text: 'Количество проведенных тендеров',
    field: 'tenders'
  },
  requests: {
    id: 3,
    img: require('@/assets/img/hammer-icon.svg'),
    number: '32 589',
    text: 'Количество поданых заявок',
    field: 'requests'
  },
  sum: {
    id: 4,
    img: require('@/assets/img/people-icon.svg'),
    number: '51 876',
    text: 'Сумма активных тендеров',
    field: 'sum'
  },
};

export default {
  namespaced: true,
  state: {
    list: [],
  },
  actions: {
    async load({ commit }) {
      httpClient.get(`${urls.apiStatisticsUrl}`).then(response => {
        commit('update', response.data)
      });
    },
  },
  mutations: {
    update(state, numbersData) {
      Object.keys(items).map(field => {
        items[field].number = formatSpacesThousands(numbersData[field]);
      });
      state.list = _.cloneDeep(Object.values(items).map((item) => item));
    }
  },
  getters: {
    list: state => state.list,
  },
}
