//import axios from 'axios'
//import { urls } from '@/config'
import _ from 'lodash'

const initialState = {
  file: null
}

export default {
  namespaced: true,
  state: initialState,
  actions: {
    download(context, id) {
      //console.info(id)
    }
  },
}

