import axios from 'axios';
import authService from "./auth.service";
import router from '../router';

/** Default config for axios instance */
const API_ENDPOINT = process.env.VUE_APP_BACKEND_URL;

let config = {
  baseURL: `${API_ENDPOINT}/`,
  /* headers: {
    'Content-Type': 'application/json',
  } */
};

/** Creating the instance for axios */
const httpClient = axios.create(config);

/** Auth token interceptors */
const authInterceptor = config => {
  const token = authService.getToken();
  if(token) config.headers.Authorization = `Bearer ${authService.getToken()}`;
  return config;
};

/** Adding the request interceptors */
httpClient.interceptors.request.use(authInterceptor);

/** Adding the response interceptors */
httpClient.interceptors.response.use(
  response => {
    /** TODO: Add any response interceptors */
    return response;
  },
  error => {
    console.info('httpClient error:', error);

    if([401, 403].includes(error.response.status)){
      router.push({name: 'login'})
    }
    /** TODO: Do something with response error */
    return Promise.reject(error);
  }
);

export default httpClient;
