import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import tender from '@/store/modules/tender'
import order from '@/store/modules/order'
import freeInformation from '@/store/modules/FreeInformation'
import faq from '@/store/modules/Faq'
import partners from '@/store/modules/Partners'
import tenderRequest from '@/store/modules/tenderRequest'
import files from '@/store/modules/files'
import profile from '@/store/modules/Profile'

export default new Vuex.Store({
  modules: {
    tender,
    order,
    freeInformation,
    faq,
    partners,
    tenderRequest,
    files,
    profile
  },
  state: () => ({

  }),
  mutations: {

  },
  actions: {

  },
  getters: {

  }
})