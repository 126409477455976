//import axios from 'axios'
//import { urls } from '@/config'
import _ from 'lodash'

const items = [
  {
    id: 1,
    question: "Обязательно ли подавать коммерческое предложение через платформу? Мне удобнее отправить по электронной почте.",
    answer: "По размещенным на площадке торгам к рассмотрению принимаются только заявки от участников, размещенные на площадке",
  },
  {
    id: 2,
    question: "Как долго проходит аккредитация нового поставщика на платформе?",
    answer: "Аккредитация нового поставщика на платформе выполняется в течение 2-4 рабочих дней",
  },
  {
    id: 3,
    question: "Как я могу узнать о проводимых тендерах?",
    answer: "При формировании тендера, информация о нем автоматически отправляется на электронные адреса всем зарегистрированным пользователям в соответствии с подтвержденными категориями и видами работ. Т.е. Вы будете автоматически уведомлены о тендерах, проводимых в рамках Ваших категорий.",
  },
  {
    id: 4,
    question: "Я хочу получать уведомление о всех проходящих тендерах. Могу ли я выбрать все категории работ при регистрации?",
    answer: "В процессе аккредитации будут подтверждены только профильные для Вашей организации категории, остальные будут исключены.",
  },
  {
    id: 5,
    question: "С платформы поступает слишком много сообщений. Как я могу отключить/настроить уведомления?",
    answer: "На странице «Управления уведомлениями» пользователь может включать и выключать возможность получения им соответствующих уведомлений. Старайтесь без необходимости не выключать уведомления, чтобы не пропускать важные события, связанные с вашим контрагентом и участием в тендерах.",
  },
  {
    id: 5,
    question: "Можно ли прикрепить несколько пользователей к одной организации?",
    answer: "Если Вы успешно зарегистрировали контрагента, вы можете прикрепить к нему прочих пользователей Вашей компании.<br>\
    Для этого они должны зарегистрироваться на портале, после чего есть два варианта действий:<br>\
    –	пользователи сами могут запросить прикрепление к контрагенту;<br>\
    –	Вы как основной пользователь можете отправить им запрос на прикрепление к вашему контрагенту; для этого необходимо:\
    <ol>\
    <li>в разделе «Мои приглашения» нажать «Добавить».</li>\
    <li>ввести точный E-mail присоединяемого зарегистрированного пользователя.</li>\
    <li>выбрать тип приглашения из следующих:<br>\
    –	Присоединить к контрагенту – пользователь будет присоединен как неосновной пользователь;<br>\
    –	Передать права полностью – ваши права основного пользователя будут переданы присоединяемому пользователю.<br>\
    </li>\
    <li>Ввести примечание для получателя (не обязательно).</li>\
    <li>Нажать «Отправить».</li>\
    </ol>\
    ",
  },
];

export default {
  namespaced: true,
  state: {
    list: [],
    loading: true,
  },
  actions: {
    async load({ commit }) {
      setTimeout(() => {
        commit('setLoading', false)
        commit('update', items)
      }, 1850);
    },
  },
  mutations: {
    update(state, numbersData) {
      state.list = _.cloneDeep(numbersData)
    },
    setLoading(state, isLoading) {
      state.loading = isLoading
    },
  },
  getters: {
    list: state => state.list,
    loading: state => state.loading,
  },
}

