export const serverUrls = {
  backendUrl: process.env.VUE_APP_BACKEND_URL,
}

export const appVersion = "1.0";

export const urls = {
  apiTendersUrl: 'restapi/tenders',
  apiOrdersUrl: 'restapi/orders',
  apiTendersDemoUrl: 'restapi/tenders-demo',
  apiStatisticsUrl: 'restapi/statistics',
  apiFileGet: 'restapi/file/get',
  apiUSerProfileUrl: '/restapi/user/profile',
  apiUSerProfileDocumentUrl: '/restapi/user/profile/document',
  apiUserSearchUrl: '/restapi/user/search',
  apiUSerProfileDelegationUrl: '/restapi/user/profile/delegation',
  apiUSerProfileRequestUrl: '/restapi/user/profile/request',
  apiUSerProfileWorkTypeUrl: '/restapi/user/profile/work_type',
}

export const fileUrls = {
  help: `${serverUrls.backendUrl}/files/Инструкция_Исполнителя_по_работе_в_АСТ_Тендеры_ЕВРАЗ_Стил_Билдинг.docx`
}
