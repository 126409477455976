import axios from 'axios'
//import { urls } from '@/config'
// import _ from 'lodash'

const inintialState = {
  transportTypes: [
    {
      name: 'Курьерской службой',
      value: 'delivery'
    },
    {
      name: 'Автомобильным транспортом',
      value: 'car'
    },
    {
      name: 'ЖД транспортом',
      value: 'train'
    },
    {
      name: 'Контейнерная перевозка',
      value: 'container'
    },
    {
      name: 'Мультимодальная перевозка',
      value: 'multimodal'
    },
    {
      name: 'Водным транспортом',
      value: 'water'
    },
  ],
  supplyConditions: [
    {
      name: 'EXW – покупатель забирает у продавца товар в месте изготовления, это называют "самовывоз". Покупатель всё делает сам: затаможку, доставку и растаможку товара.',
      value: '1'
    },
    {
      name: 'FCA – продавец должен затаможить товар и погрузить его в предоставленный покупателем транспорт. Покупатель обязан через перевозчика выдать продавцу документ об отгрузке товара для возмещения НДС.',
      value: '2'
    },
    {
      name: 'FAS – продавец должен доставить товар на причал и расположить его напротив указанного судна. Покупатель принимает товар и осуществляет его погрузку.',
      value: '3'
    },
    {
      name: 'FOB – продавец затамаживает груз и размещает его на судне, после этого ответственность переходит к покупателю.',
      value: '4'
    },
    {
      name: 'CFR – продавец затамаживает груз, размещает его на судне и доставляет до порта назначения. После размещения груза на судне ответственность переходит к покупателю. Страховку груза осуществляет покупатель.',
      value: '5'
    },
    {
      name: 'CIF – как CFR продавец осуществляет затаможку, погрузку и перевозку груза. Отличие от CFR состоит в страховке груза продавцом.',
      value: '6'
    },
    {
      name: 'CPT – продавец осуществляет затаможку, погрузку и перевозку груза. После погрузки на транспорт перевозчика ответственность переходит к покупателю, хотя доставка оплачивается продавцом. Страховой договор продавец заключать не обязан.',
      value: '7'
    },
    {
      name: 'CIP – продавец осуществляет затаможку груза, погрузку, перевозку и разгрузку, после этого ответственность переходит покупателю. Страховку груза осуществляет продавец.',
      value: '8'
    },
    {
      name: 'DPU (ранее DAT) – продавец затаможивает груз, перевозит и разгружает в терминале, после ответственность за груз переходит к покупателю. Страхование груза не входит в обязанности продавца.',
      value: '9'
    },
    {
      name: 'DAP – продавец затаможивает груз, перевозит его и предоставляет покупателю для разгрузки, после этого ответственность за груз переходит покупателю. Страхование груза в обязанности продавца не входит.',
      value: '10'
    },
    {
      name: 'DDP – продавец должен затаможить груз и доставить его в указанное покупателем место, разгрузить и растаможить его. Страхование груза в обязанности продавца не входит.',
      value: '11'
    }
  ],
  deliveryConditions: [
    {
      name: 'Самовывоз',
      value: '1'
    },
    {
      name: 'Силами поставщика с последующей компенсацией покупателем затрат на перевозку',
      value: '2'
    },
    {
      name: 'Силами поставщика за счет поставщика',
      value: '3'
    },
  ],
  tenderPosTableColumns: [
    {
      field: 'nomenclature',
      label: 'НОМЕНКЛАТУРА',
      width: 400,
      type: 'description'
    },
    {
      field: 'venderCode',
      label: 'АРТИКУЛ',
    },
    {
      field: 'measureUnit',
      label: 'ЕД.ИЗМ',
    },
    {
      field: 'quantity',
      label: 'КОЛ-ВО',
    },
    {
      field: 'materialUnit',
      label: 'ЕДИНИЧ. расц. мат-лов, руб',
      width: 85,
      type: 'editable'
    },
    {
      field: 'workUnit',
      label: 'ЕДИНИЧ. расц. работ, руб',
      width: 85,
      type: 'editable'
    },
    {
      field: 'note',
      label: 'ПРИМЕЧАНИЕ',
      width: 85,
      type: 'editable'
    },
    {
      field: 'usd',
      label: '$',
      type: 'checkable'
    },
    {
      field: 'materialPrice',
      label: 'стоим. мат-лов, руб',
      type: 'computable',
    },
    {
      field: 'workPrice',
      label: 'стоим. работ, руб',
      type: 'computable',
    },
    {
      field: 'ndsPrice',
      label: 'стоим. с ндс, руб',
      type: 'computable'
    },
  ],
  tenderPosTableData: [
    {
      nomenclature: {
        title: 'Профнастил оцинкованый Н60-845',
        description: 'Профнастил оцинкованый Н60-845'
      },
      venderCode: '',
      measureUnit: 'Компл',
      quantity: '',
      materialUnit: '',
      workUnit: '',
      note: '',
      usd: false,
      materialPrice(usdPrice = 1) { return parseFloat(this.quantity * this.materialUnit * (this.usd ? usdPrice : 1)).toFixed(3) },
      workPrice(usdPrice = 1) { return parseFloat(this.quantity * this.workUnit * (this.usd ? usdPrice : 1)).toFixed(3) },
      ndsPrice(usdPrice = 1) { return parseFloat(this.materialUnit * this.workUnit * (this.usd ? usdPrice : 1)).toFixed(3) }
    },
    {
      nomenclature: {
        title: 'Профнастил оцинкованый Н60-845',
        description: 'Профнастил оцинкованый Н60-845',
        child: true
      },
      venderCode: '',
      measureUnit: 'Компл',
      quantity: '5',
      materialUnit: '',
      workUnit: '',
      note: '',
      usd: false,
      materialPrice(usdPrice = 1) { return parseFloat(this.quantity * this.materialUnit * (this.usd ? usdPrice : 1)).toFixed(3) },
      workPrice(usdPrice = 1) { return parseFloat(this.quantity * this.workUnit * (this.usd ? usdPrice : 1)).toFixed(3) },
      ndsPrice(usdPrice = 1) { return parseFloat(this.materialUnit * this.workUnit * (this.usd ? usdPrice : 1)).toFixed(3) }
    }
  ],
  USDPrice: null,
  orderId: null,
  orderData: null,
  orderStatusId: null,
  orderFiles: { offer: null, protocol: null, otherDocs: null },
  tenderPreview: [
    {
      name: 'Информация о тендере',
      params: [
        {
          name: 'текущий статус:',
          value: 'ОТКРЫТ',
          type: 'status'
        },
        {
          name: 'Вид тендера:',
          value: 'Тендер',
        },
        {
          name: 'ДАТА НАЧАЛА ПРОВЕДЕНИЯ:',
          value: '29 декабря 2021, 10:02',
        },
        {
          name: 'ДАТА окончания ПРОВЕДЕНИЯ:',
          value: '29 декабря 2021, 10:02',
        },
        {
          name: 'НОМЕНКЛАТУРА:',
          value: 'Профнастил Н114-750',
        },
        {
          name: 'КРАТКОЕ ОПИСАНИЕ:',
          value: 'Профнастил Н114-750',
        },
        {
          name: 'ОПИСАНИЕ:',
          value: 'Профнастил Н114-750',
        },
        {
          name: 'Категория:',
          value: 'ТМЦ',
        },
        {
          name: 'Проект:',
          value: 'Фермы',
        },
        {
          name: 'Обьект:',
          value: 'Фермы',
        },
        {
          name: 'дата начала исполнения:',
          value: '29 декабря 2021, 10:02',
        },
        {
          name: 'дата окончания исполнения:',
          value: '29 декабря 2021, 10:02',
        },
        {
          name: 'СРОК ИСПОЛНЕНИЯ, дней:',
          value: '20',
        },
        {
          name: 'Разрешить ввод нулевых строк:',
          value: 'Да',
        }
      ]
    },
    {
      name: 'Заявка',
      params: [
        {
          name: 'СТоимость с учетом ндс, руб',
          value: '1 000 000 000',
        },
        {
          name: 'условия ПОСТАВКИ:',
          value: '-',
        },
        {
          name: 'Затраты на доставку:',
          value: '50 000',
        },
        {
          name: 'срок поставки:',
          value: '29 декабря 2021, 10:02',
        },
        {
          name: 'вид танспорта:',
          value: 'Авто',
        },
        {
          name: 'порядок доставки:',
          value: 'Самовывоз',
        },
        {
          name: 'условия расчетов:',
          value: 'Аванс',
        },
        {
          name: 'Сроки предоплаты:',
          value: '5 дней',
        },
      ]
    }
  ]
}

export default {
  namespaced: true,
  state: inintialState,
  actions: {
    getUSDPrice({ commit }) {
      axios.get('https://www.cbr-xml-daily.ru/latest.js').then(res => {
        commit('setUSDPrice', res.data.rates.USD)
      })
    }
  },
  mutations: {
    setUSDPrice(state, value) {
      state.USDPrice = value
    },
    setOrderId(state, value) {
      state.orderId = value
    },
    setOrderData(state, value) {
      state.orderData = value
    },
    setOrderFiles(state, orderFiles) {
      state.orderFiles[orderFiles.name] = orderFiles.files ? orderFiles.files : [];
    },
    setOrderStatusId(state, value) {
      state.orderStatusId = value
    },
    clearOrderData(state) {
      state.orderId = null;
      state.orderStatusId = null;
      state.orderData = null;
    }
  },
  getters: {
    transportTypes: state => state.transportTypes,
    supplyConditions: state => state.supplyConditions,
    deliveryConditions: state => state.deliveryConditions,
    tenderPosTableColumns: state => state.tenderPosTableColumns,
    tenderPosTableData: state => state.tenderPosTableData,
    USDPrice: state => state.USDPrice,
    orderId: state => state.orderId,
    orderStatusId: state => state.orderStatusId,
    tenderPreview: state => state.tenderPreview,
    orderFiles: state => state.orderFiles,
    orderData: state => state.orderData,
  },
}