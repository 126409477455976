import httpClient from "./http.service";
import router from "../router";

const authService = {
  currentUser: null,
  isLoggedIn() {
    return !!localStorage.getItem('ACCESS_TOKEN')
  },
  getToken() {
    return localStorage.getItem('ACCESS_TOKEN')
  },
  getUsername() {
    return localStorage.getItem('USERNAME')
  },
  getAuthorizedName() {
    return localStorage.getItem('TENDER_AUTHORIZED_NAME')
  },
  getEmail() {
    return localStorage.getItem('TENDER_AUTHORIZED_EMAIL')
  },
  async login(formData) {
    try {
      const { data } = await httpClient.post('/restapi/user/login', formData);

      if (data.status === 'success') {
        localStorage.setItem('ACCESS_TOKEN', data.access_token)
        localStorage.setItem('USERNAME', data.username);
        localStorage.setItem('TENDER_AUTHORIZED_NAME', data.name || '')
        localStorage.setItem('TENDER_AUTHORIZED_EMAIL', data.email || '')

        return {
          success: true
        }
      }

      throw data;
    } catch (e) {
      console.info('Login error: ', e);
      return {
        success: false,
        errors: e.errors
      }
    }
  },
  async register(formData) {
    try {
      const { data } = await httpClient.post('/restapi/sign-up', formData);
      if (data.status === 'success') {
        localStorage.setItem('ACCESS_TOKEN', data.user.access_token);

        return {
          success: true
        }
      }
      throw data;
    } catch (e) {
      console.info('Registration error: ', e);
      return {
        success: false,
        errors: e.errors
      }
    }
  },
  logout() {
    localStorage.removeItem('ACCESS_TOKEN');
    localStorage.removeItem('USERNAME');
    localStorage.removeItem('TENDER_AUTHORIZED_NAME');
    localStorage.removeItem('TENDER_AUTHORIZED_EMAIL');
    router.go({ name: "Home" });
  },
  async recover(email) {
    try {
      const { status, data } = await httpClient.post('/restapi/recover', { email: email });

      return {
        success: true,
        message: data.message
      }
    } catch (e) {
      console.info(e.response);
      return {
        success: false,
        errors: e.response.data.errors
      }
    }
  },
  async setPassword(id, code, password) {
    try {
      const data = await httpClient.post('/restapi/set-password', { id: id, code: code, password: password });
      const { success, message, errors } = data.data;

      return {
        success: success,
        message: message,
        errors: errors,
      }
    } catch (e) {
      console.info('e.response', e.response);
      return {
        success: false,
        errors: e.response.data.errors
      }
    }
  },
  async getUser() {
    try {
      if (!this.currentUser) {
        const { status, data } = await httpClient.get('/restapi/user/data');
        if (status === 200) {
          this.currentUser = data;
        }
      }

    } catch (e) {
      return null;
    }

    return this.currentUser;
  }
};

export default authService;
