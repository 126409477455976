export function formatSpacesThousands(val) {
    if (!val) return 0;
    if (typeof val === 'number' || !isNaN(parseFloat(val))) {
        const _val = val;
        var num_parts = _val.toString().split('.');
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        return num_parts.join('.');
    }
}

export function deFormatSpacesThousands(val) {
    return Number(
        val
            .toString()
            .split(' ')
            .join(''),
    );
}
